<template>
  <div class="home">
    <change-tmp-password>
      <v-icon slot="eye-icon" name="eye"></v-icon>
      <v-icon slot="eye-icon-closed" name="eye-off"></v-icon>
    </change-tmp-password>
  </div>
</template>

<script>
export default {
  name: 'LoginView',
  methods: {},
};
</script>
